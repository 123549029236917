
//邮箱正则校验
export function checkEmail(str) {
  const reg = /^[a-zA-Z0-9]+[a-zA-Z0-9._-]+@([a-zA-Z0-9]+[a-zA-Z0-9_-]+\.)+[a-z]{2,}$/;
  return reg.test(str);
}
//密码正则校验:密码为4-20位，不允许出现空字符
export function checkPwd(str) {
  const reg = /^[^ ]{4,20}$/;
  return reg.test(str);
}
//手机号码验证(11位数字)
export function checkPhone(str) {
  let reg = /^([1][\d]{10})$/;
  return reg.test(str);
}

//注册码校验(6位数字)
export function checkReg(str) {
  let reg = /^\d{6}$/;
  return reg.test(str);
}

/**
 * 网关名称、任务名称、设备名称
 * 支持汉字、英文，数字、下划线（_）、长度限制30字符
 */
export function checkName(str) {
  const reg = /^[a-zA-Z0-9\u4e00-\u9fa5_]{1,30}$/;
  return reg.test(str);
}

/**
 * 网关名称、任务名称、设备名称
 * 只有下划线（_）、不能通过
 */
export function check_(str) {
  const reg = /^[_]{1,99}$/;
  return reg.test(str);
}

/**
 * 设备分组名称、用户分组名称
 * 支持汉字、英文，数字、下划线（_）、长度限制40字符
 *
 */
export function checkGroupName(str) {
  const reg = /^[a-zA-Z0-9\u4e00-\u9fa5_]{1,40}$/;
  return reg.test(str);
}
/**
 * 账户名称
 * 支持中文、英文限制2-20位
 */
export function checkAccountName(str) {
  const reg = /^[0-9a-zA-Z\u4e00-\u9fa5]{2,20}$/;
  return reg.test(str);
}

//清除localStorage
export function clearLocalStorage() {
  localStorage.removeItem('userName');
  localStorage.removeItem('userId');
  localStorage.removeItem('groupId');
  localStorage.removeItem('roleCode');
  localStorage.removeItem('wsUuid');
}

//IP地址校验
export function checkIP(str) {
  const reg = /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
  return reg.test(str);
}

//端口号校验（0-65535）
export function checkPort(str) {
  const reg = /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
  return reg.test(str);
}

//非0端口号校验（1-65535）
export function checkPortWithout0(str) {
  const reg = /^([1-9](\d{0,3}))$|^([1-5]\d{4})$|^(6[0-4]\d{3})$|^(65[0-4]\d{2})$|^(655[0-2]\d)$|^(6553[0-5])$/;
  return reg.test(str);
}

//MAC地址校验
export function checkMAC(str) {
  const reg = /^([0-9a-fA-F]{2})(([/\s:-][0-9a-fA-F]{2}){5})$/;
  return reg.test(str);
}

//非负整数校验
export function checkNonnegativeInteger(str) {
  const reg = /^[0-9]*$/;
  return reg.test(str);
}

//网关校验: 校验IP地址，子网掩码和默认网关的关系是否正确
//w是默认网关，y是子网掩码，i是IP地址
export function checkGateway(w, y0, i) {
  let zwArr = [
    { value: '1', label: '128.0.0.0' },
    { value: '2', label: '192.0.0.0' },
    { value: '3', label: '224.0.0.0' },
    { value: '4', label: '240.0.0.0' },
    { value: '5', label: '248.0.0.0' },
    { value: '6', label: '252.0.0.0' },
    { value: '7', label: '254.0.0.0' },
    { value: '8', label: '255.0.0.0' },
    { value: '9', label: '255.128.0.0' },
    { value: '10', label: '255.192.0.0' },
    { value: '11', label: '255.224.0.0' },
    { value: '12', label: '255.240.0.0' },
    { value: '13', label: '255.248.0.0' },
    { value: '14', label: '255.252.0.0' },
    { value: '15', label: '255.254.0.0' },
    { value: '16', label: '255.255.0.0' },
    { value: '17', label: '255.255.128.0' },
    { value: '18', label: '255.255.192.0' },
    { value: '19', label: '255.255.224.0' },
    { value: '20', label: '255.255.240.0' },
    { value: '21', label: '255.255.248.0' },
    { value: '22', label: '255.255.252.0' },
    { value: '23', label: '255.255.254.0' },
    { value: '24', label: '255.255.255.0' },
    { value: '25', label: '255.255.255.128' },
    { value: '26', label: '255.255.255.192' },
    { value: '27', label: '255.255.255.224' },
    { value: '28', label: '255.255.255.240' },
    { value: '29', label: '255.255.255.248' },
    { value: '30', label: '255.255.255.252' },
    { value: '31', label: '255.255.255.254' },
    { value: '32', label: '255.255.255.255' },
  ];

  let y;
  zwArr.forEach((item) => {
    if (item.value == y0) {
      y = item.label;
    }
  });

  let ws = w.split('.');
  let ys = y.split('.');
  let is = i.split('.');

  for (let index in ws) {
    let cw = to8Bit(ws[index]);
    let cy = to8Bit(ys[index]);
    let ci = to8Bit(is[index]);

    for (let jndex = 0; jndex < 8; jndex++) {
      if (cy[jndex] == '1') {
        if (cw[jndex] != ci[jndex]) return false;
      }
    }
  }

  return true;
}

export function to8Bit(number) {
  let result = parseInt(number).toString(2);
  while (result.length < 8) {
    result = '0' + result;
  }
  return result;
}

export function getNetMaskEnum(){
  
}