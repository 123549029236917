import axios from 'axios';
import { Message } from 'element-ui';
import cookie from '@/utils/cookie';
import { clearLocalStorage } from '../utils/common';

//创建axios实例
const instance = axios.create({
  baseURL: '/api',
  timeout: 3600000,
  headers: {
    'Content-Type': 'application/json',
  },
});

//请求拦截器
instance.interceptors.request.use(
  (config) => {
    /*
     * get、delete请求---query请求
     * post、put、patch --- 传参格式 Request Payload，无需序列化处理
     * config.data  post请求下的参数
     * config.params  get请求下的参数
     */

    let token = localStorage.getItem('token') || '';
    config.headers.Authorization = token;

    // let token = cookie.get('token') || '';
    // config.headers.Authorization = 'Bearer ';
    // if (config.method === 'get') {
    //   //  给data赋值以绕过if判断
    //   config.data = true
    // }
    // config.headers['Content-Type'] = 'application/json'

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
instance.interceptors.response.use(
  (response) => {
    console.log(response)
    if (undefined!=response.config.responseType
          &&response.config.responseType =="arraybuffer") {
      Message({
        message:'导出成功',
        type: 'success',
        center: true,
      });
      return null;
    }
    if (response.data.code != 200) {

      Message({
        message: response.data.msg || '服务器异常,请稍后重试',
        type: 'error',
        center: true,
      });
      return null;
    } else {
      return response.data;
    }



    // if (response.data && response.data.code && response.data.code === 200 && response.data.responseData === null) {
    //   Message({
    //     message: response.data.msg || '服务器异常,请稍后重试',
    //     type: 'error',
    //     center: true,
    //   });
    //   return null;
    // } else if (response.data && response.data.code && response.data.code !== 200) {
    //   Message({
    //     message: response.data.msg || '服务器异常,请稍后重试',
    //     type: 'error',
    //     center: true,
    //   });
    //   window.location.href = '/#/';
    // } else {
    //   return response.data;
    // }
  },
  (error) => {
    console.log(error)
    let { data } = error.response;
    if (data.code === 401) {
      cookie.clear();
      clearLocalStorage();
      window.location.href = '/#/';
    } else if (data.code === 402 || data.code === 405) {
      Message({
        message: data.msg,
        type: 'warning',
        center: true,
      });
    } else {
      Message({
        message: data.msg || '服务器异常,请稍后重试',
        type: 'error',
        center: true,
      });
      window.location.href = '/#/';
    }
    return Promise.reject(error.response.data);
  }
);

export default instance;
